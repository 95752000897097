<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br />
        <br />
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit App List</strong>
            </div>
            <div class="text-muted">Edit App List information</div>
          </div>
          <div class="card-body" v-if="appListData">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>App List's name.</span>
                  </template>
                  <a-input type="text" placeholder="App List Name" v-decorator="['name', {
                    initialValue: appListData.Name,
                    rules: [
                      { required: true, message: 'Name is required.' },
                      { max: 64, message: 'Maximum 64 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="List Text">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>List of App bundles separated by new line.</span>
                  </template>
                  <a-textarea :autoSize="{ minRows: 1, maxRows: 20 }" type="text" placeholder="App List Name"
                    v-decorator="['listText', {
                      initialValue: appListData.ListText,
                    }]" />
                </a-tooltip>
              </a-form-item>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                    :class="'btn btn-' + (appListData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-2'"
                    @click.prevent="toggleAppListStatus">
                    {{ appListData.Status == 0 ? 'Deactivate' : 'Activate' }} App List
                  </button>
                  <button type="button" class="btn px-5 ml-2" @click="$router.push({ name: 'applists' })">
                    Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { getAppListById } from '@/api/display/applist'

export default {
  components: {},
  computed: {
    // appListData: function () {
    //   let appListData = this.$store.getters['applist/getAppListData'](this.$route.params.id)
    //   if (typeof appListData === 'undefined') {
    //     this.$store.dispatch('applist/LOAD_APPLISTS')
    //     appListData = {}
    //   }
    //   return appListData
    // },
  },
  data() {
    return {
      originalListText: null,
      appListData: null,
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values.listText, this.originalListText, 'yes or no')
          if (values.listText === this.originalListText) {
            delete values.listText
          }
          this.$store.dispatch('applist/UPDATE_APPLIST', {
            id: this.appListData.Id,
            payload: {
              ...values,
            },
            callback: function () {
              this.$router.push({ name: 'applists' }).catch(() => { })
            }.bind(this),
          })
        }
      })
    },
    toggleAppListStatus() {
      const status = this.appListData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change App list status?',
        content: h => <div>{status} App list <strong>{this.appListData.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = this.appListData.Status === 0 ? 1 : 0
          this.appListData.Status = newStatus
          this.$store.dispatch('applist/CHANGE_STATUS', {
            id: this.appListData.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
  },
  created() {
    getAppListById(this.$route.params.id)
      .then((res) => {
        this.appListData = res
        this.originalListText = res.ListText
      })
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
